<script setup lang="ts">
import {
  getTranslatedProperty,
  getCategoryRoute,
} from "@shopware-pwa/helpers-next";

import { inject } from "vue";

import {
  faXing,
  faTiktok,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { apiClient } = useShopwareContext();

const footerInfo = ref(null);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});

const { data: footerElements } = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 1 });
});

const footerId = footerElements?.value?.at(0)?.parentId || "";

const fetchFooterInfo = async () => {
  try {
    const categories = await apiClient.invoke(
      `readCategoryList post /category`,
      {},
    );
    footerInfo.value =
      categories.elements?.filter((item) => item.id === footerId)[0] || null;
  } catch (e) {
    console.log(e.message);
  }
};
fetchFooterInfo();

const getSocialIcon = (navigationChild) => {
  const link = navigationChild.externalLink;

  if (link.includes("linkedin.com")) {
    return faLinkedin;
  }
  if (link.includes("instagram.com")) {
    return faInstagram;
  }
  if (link.includes("facebook.com")) {
    return faFacebook;
  }
  if (link.includes("xing.com")) {
    return faXing;
  }
  if (link.includes("youtube.com")) {
    return faYoutube;
  }
  if (link.includes("tiktok.com")) {
    return faTiktok;
  }

  return faGlobe;
};
</script>

<template>
  <v-footer class="border-radius-footer bg-gray50">
    <v-container>
      <v-row class="mb-2">
        <v-col
          v-for="navigationElement in footerElements"
          :key="navigationElement.id"
          cols="12"
          sm="6"
          md="4"
        >
          <span class="mb-2 text-sm text-gray500">
            {{ getTranslatedProperty(navigationElement, "name") }}
          </span>

          <v-list v-if="navigationElement.childCount > 0" bg-color="gray50">
            <v-list-item
              v-for="navigationChild in navigationElement.children"
              :key="navigationChild.id"
              base-color="#51525C"
              class="px-0 nav-item-footer"
            >
              <v-link
                density="compact"
                :target="
                  navigationChild.externalLink || navigationChild.linkNewTab
                    ? '_blank'
                    : ''
                "
                :to="formatLink(getCategoryRoute(navigationChild))"
              >
                <template v-if="navigationChild.externalLink">
                  <span class="social-icon-wrapper">
                    <font-awesome-icon :icon="getSocialIcon(navigationChild)" />
                  </span>
                </template>

                {{ getTranslatedProperty(navigationChild, "name") }}
              </v-link>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <a
            href="https://fpm.climatepartner.com/tracking/20260-2210-1001/de?utm_source=(direct)&utm_medium=climatepartner.com"
            target="_blank"
          >
            <v-img
              class="mr-sm-auto"
              height="36"
              width="166"
              src="/images/footer/climatepartner-onecrowd.svg"
            />
          </a>
        </v-col>

        <v-col cols="12" sm="4">
          <a href="/efre" target="_blank">
            <v-img
              class="mx-sm-auto"
              height="36"
              width="226"
              src="/images/footer/sachsen-mitfinanziert.png"
            />
          </a>
        </v-col>

        <v-col cols="12" sm="4">
          <a href="/efre" target="_blank">
            <v-img
              class="ml-sm-auto"
              height="36"
              width="170"
              src="/images/footer/eu-kofinanziert.png"
            />
          </a>
        </v-col>
      </v-row>

      <div class="page-content-footer text-sm text-gray400 mt-8">
        <div v-if="footerInfo" v-html="footerInfo.description"></div>
      </div>

      <v-divider class="mb-8 border-opacity-100" color="gray900"></v-divider>

      <v-row align-content="space-between" class="mb-4">
        <v-col>
          <NuxtLink :to="formatLink(`/`)">
            <span class="sr-only">OneCrowd</span>
            <img
              src="/logo_footer.svg"
              alt="logo of the shop"
              style="height: 1rem; width: auto"
            />
          </NuxtLink>
        </v-col>

        <v-col class="text-right text-body-2">
          &copy; {{ new Date().getFullYear() }} OneCrowd
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<style scoped>
.v-footer.border-radius-footer {
  border-top-left-radius: var(--v-border-radius-xxl);
  border-top-right-radius: var(--v-border-radius-xxl);
  padding: var(--v-border-radius-xxl) 0 0 0;
}

.v-footer .nav-item-footer {
  font-weight: 600;
}

.v-footer .page-content-footer p {
  margin-bottom: 1rem;
}

.social-icon-wrapper > svg {
  width: auto;
  height: 16px;
  display: inline;
  margin-right: 8px;
}
</style>
